import { AfterViewInit, Component, Injector, signal, ViewChild} from '@angular/core';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import * as routeList  from '@src/app/core/constants/routes.constant';
import { TableModule } from "../../../../core/shared/components/table/table.module";
import { ConfirmDialogModule } from "../../../../core/shared/components/confirm-dialog/confirm-dialog.module";
import { TableComponent } from '@src/app/core/shared/components/table/table.component';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { ApiEndPointConfiguration } from '@src/app/app-types';
import { BehaviorSubject } from 'rxjs';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { AllowedTableActionsData, ROW_NOT_CLICKABLE } from '@src/app/core/constants/table-actions.constant';
import { AllowedTableActions } from '@src/app/core/interfaces/table-actions.interface';
import { BaseComponent } from '@src/app/core/base';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ARTICLE_BASE_URL, ARTICLE_STATUS_UPDATE } from '../../core/constants/apis_list';
import { BLOG_TAGS_BASE_URL } from '@src/app/features/blog-tags/core/constants/apis_list';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { JobsRepository } from '../../core/repositories/jobs.repository';

@Component({
  selector: 'app-jobs-listing',
  standalone: true,
  imports: [...COMMON_MODULES,TableModule,NgbDropdownModule, ConfirmDialogModule],
  templateUrl: './jobs-listing.component.html',
  styleUrl: './jobs-listing.component.scss',
})
export class JobsListingComponent extends BaseComponent implements AfterViewInit  {
  @ViewChild(TableComponent, { static: false }) tableCompRef!: TableComponent;
  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;


  public displayedColumnsViewArray: any[] = [];
  public allowedTableActions: AllowedTableActions = AllowedTableActionsData;
  public paramFilters: any[] = [
    {
      key: 'filter.isPublished',
      type: 'option',
      placeholder: 'COMMON.TEXT.STATUS',
      operation: '$eq:',
      options: this.constantList.JOBS_STATUS
    },
    {
      key: 'filter.isPublished',
      type: 'option',
      placeholder: 'COMMON.TEXT.TYPE',
      operation: '$eq:',
      options: this.constantList.JOBS_TYPES
    },
    {
      type: 'date-from',
      key: 'filter.dateFrom',
      placeholder: 'COMMON.TEXT.FROM',
      filterKey: 'filter.createdAt',
      name: 'filter.dateFrom',
      maxTo: 'filter.dateTo',
    },
    {
      type: 'date-to',
      key: 'filter.dateTo',
      placeholder: 'COMMON.TEXT.TO',
      filterKey: 'filter.createdAt',
      name: 'filter.dateTo',
      minFrom: 'filter.dateFrom',
    },
  ];

  public endPointConfiguration: ApiEndPointConfiguration = {
    method: 'GET',
    url: ARTICLE_BASE_URL, // Placeholder API endpoint for articles
  };

  private selectedRows = signal([]);
  public articles$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public confirmEventAction: 'delete' | null = null;

  public isEditPermission = this.sharedDataService.checkPermission(
  [this.permissionsList.UPDATE_USERS],
  undefined,
  );

  constructor(
    private injector: Injector, // Angular's injector service
    private readonly pageSettingsService: PageSettingsService, // Service for managing page settings
    private readonly articlesRepository: JobsRepository,
  ) {
     // Calling the parent constructor (BaseComponent) and passing the injector
     super(injector);

      // Initializing allowed table actions
      this.allowedTableActions.pending = true; // Setting 'pending' action as allowed
      this.allowedTableActions.draft = false; // Setting 'draft' action as not allowed

    // Update breadcrumb settings for the page
    const newSettings = {
      link1: {
        title: 'BREADCRUMBS.TASK_AND_PROJECTS.TITLE', // Breadcrumb title
        name: 'MENU.JOBS', // Breadcrumb name
        link: this.routeList.JOBS, // Link for the breadcrumb
      },
    };
    this.pageSettingsService.updatePageSettings(newSettings);

  }

  ngAfterViewInit(): void {
    // Configure table columns
    setTimeout(() => {
      this.displayedColumnsViewArray = [
        {
          key: 'title',
          value: 'COMPONENTS.TASK_AND_PROJECTS.JOB_TITLE',
          type: 'text',
          sortable: false,
          className: 'text-truncate'
        },
        {
          key: 'title',
          value: 'COMPONENTS.TASK_AND_PROJECTS.JOB_OWNER',
          type: 'text',
          sortable: false,
          className: 'text-truncate'
        },
        {
          key: 'title',
          value: 'COMPONENTS.TASK_AND_PROJECTS.JOB_TYPE',
          type: 'text',
          sortable: false,
          className: 'text-truncate'
        },
        {
          key: 'id',
          value: 'COMPONENTS.TASK_AND_PROJECTS.ASSIGNED_ID',
          headingClass: 'ps-4',
          type: 'text',
          sortable: false,
          className: 'w-10'
        },
        {
          key: 'title',
          value: 'COMPONENTS.TASK_AND_PROJECTS.DOWN_PAYMENT',
          type: 'text',
          sortable: false,
          className: 'text-truncate'
        },
        {
          key: 'title',
          value: 'COMPONENTS.TASK_AND_PROJECTS.FINAL_PAYMENT',
          type: 'text',
          sortable: false,
          className: 'text-truncate'
        },
        {
          key: 'title',
          value: 'COMPONENTS.TASK_AND_PROJECTS.TOTAL_BUDGET',
          type: 'text',
          sortable: false,
          className: 'text-truncate'
        },
        {
          key: 'title',
          value: 'COMPONENTS.TASK_AND_PROJECTS.START_DATE',
          type: 'datetime',
          sortable: false,
          className: 'text-truncate'
        },
        {
          key: 'title',
          value: 'COMPONENTS.TASK_AND_PROJECTS.END_DATE',
          type: 'datetime',
          sortable: false,
          className: 'text-truncate'
        },
        {
          key: 'status',
          value: 'COMPONENTS.TASK_AND_PROJECTS.STATUS',
          headingClass: 'ps-4',
          type: 'status',
          keyValue: 'isPublished', // Reference isPublished for status mapping
          sortable: false,
          options: [
            {
              label: 'GENERAL.BUTTONS.PUBLISHED',
              value: 'Published',
              icon: 'tick-circle',
            },
            {
              label: 'GENERAL.BUTTONS.UNPUBLISHED',
              value: 'Unpublished',
              icon: 'close-circle',
            },
          ],
          endpoint: ARTICLE_STATUS_UPDATE,
          success: this.translate.instant('MSGS.ARTICLES.UPDATE_ARTICLE_STATUS'),
          successSecond: this.translate.instant('MSGS.ARTICLES.UPDATE_ARTICLE_STATUS_DEACTIVATED'),
          alert: this.translate.instant('MSGS.ARTICLES.ARE_YOU_SURE_UNPUBLISH'),
          alertSecond: this.translate.instant('MSGS.ARTICLES.ARE_YOU_SURE_PUBLISH'),
          error: this.translate.instant('MSGS.ARTICLES.UNABLE_TO_UPDATE_Article_STATUS'),
        },
      ];
    }, 500);
  }

  public havePermissionToDelete = (permission: any) => {
    // Check if the permission object is an array and if it contains a role with code 'super_admin'
    return (
      Array.isArray(permission?.roles) && // Check if permission.roles is an array
      permission?.roles.some((role: any) => role?.code === 'super_admin') // Check if any role has code 'super_admin'
    );
  };


  public onElementClick(e: any) {
    // Handle different actions based on the event object

    // If 'element' property is an array with length greater than 0, set selectedRows
    if (e.element.length > 0) {
      this.selectedRows.set(e.element);
    }
    // If 'element' property is not clickable and has an ID 'from_td', navigate to user listing route
    else if (!ROW_NOT_CLICKABLE.includes(e.action) && e.id && e.id === 'from_td') {
      this.helperService.goToRoute(this.routeList.JOBS + `/${e.element.id}`);
    }
    // If 'element' property has an ID but no action, return
    else if (e.id) {
      return;
    }

    // Switch statement to handle different actions
    switch (e.action) {
      case 'delete':
        // If confirmDialCompRef exists, open delete dialog
        if (this.confirmDialCompRef) {
          this.confirmEventAction = 'delete';
          this.confirmDialCompRef.openDialog(e.element);
        }
        break;
      case 'email':
        // If action is 'email', navigate to user listing route
        this.helperService.goToRoute(this.routeList.JOBS + `/${e.element.id}`);
        break;
    }
  }

    public processModalClose(event: {
      data: any;
      eventType: string | null;
      confirmed: boolean;
      action: 'confirm' | 'dismiss';
    }) {
      // Switch case based on event type
      if (event?.eventType === 'delete') {
        // If action is confirm
        if (event.action === 'confirm') {
          // Check permission to delete
          if (!this.havePermissionToDelete(event.data)) {
            // Delete item
            this.articlesRepository
              .delete(event.data?.id)
              .pipe(this.destroy$())
              .subscribe({
                /**
                 * Handles the response from the delete operation.
                 *
                 * @param {any} res - The response from the delete operation.
                 * @return {void}
                 */
                next: (response: unknown) => {
                  const res = response as ApiResponseInterface<any>;
                  // If delete operation is successful
                  if (res.status === this.constantList.SUCCESS_STATUS || res.status === this.constantList.SUCCESS_STATUS_CODE) {
                    // Update table
                    this.updateTable(this.translate.instant('MSGS.ARTICLES.DELETE_ARTICLE_SUCCESS'));
                  } else {
                    // Show error message
                    this.httpService.showMessage(
                      this.translate.instant('MSGS.ARTICLES.UNABLE_TO_DELETE'),
                      'error',
                    );
                  }
                },
                error: (err) => {
                  this.httpService.showMessage(
                    this.translate.instant('MSGS.ARTICLES.UNABLE_TO_DELETE'),
                    'error'
                  );
                }
              });
          } else {
            // Show error message
            this.httpService.showMessage(
              this.translate.instant('MSGS.USERS.UNABLE_TO_DELETE_SUPER_ADMIN'),
              'error',
            );
          }
        }
      }
    }

    private updateTable(msg: string) {
      // Show success message
      this.httpService.showMessage(msg, 'success');
      // Reload the table data
      this.tableCompRef.loadResourcesPage();
      // Clear the bulk select list
      this.tableCompRef.bulkSelectList = [];
    }


  public tableAction(event: any) { }

}
