import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbDatepickerModule, NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from '@src/app/core/base';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { AppStatus } from '@src/app/core/shared/components/app-status/app-status.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import { DebounceClickDirectiveModule } from '@src/app/core/shared/directives/debounceClick/debounceClick.directive.module';
import { NoConsecutiveSpacesDirectiveModule } from '@src/app/core/shared/directives/noConsecutiveSpaces/noConsecutiveSpaces.directive.module';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { SharedModule } from '@src/app/core/shared/shared.module';
import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-saz';
import { NewProductPhotosInputComponent } from '@src/app/core/shared/components/new-product-photos-input/new-product-photos-input.component';
import { OnlyIntegerDirectiveModule } from '@src/app/core/shared/directives';
import { LoaderModule } from '@src/app/core/shared/components/loader/loader.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import {  STATUS_ARTICLES } from '@src/app/core/constants';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { contentLimitValidator } from '@src/app/core/utils/content-limit-validator.util';


export enum Role {
  Admin = 'admin',
  'Content Creator' = 'content_creator',
  Operator = 'operator',
}
@Component({
  selector: 'app-job-detail',
  standalone: true,
  templateUrl: './job-detail.component.html',
  styleUrl: './job-detail.component.scss',
  imports: [
    ...COMMON_MODULES,
    NgbNavModule,
    NgSelectModule,
    ConfirmDialogModule,
    NgbDropdownModule,
    NgbTooltipModule,
    DebounceClickDirectiveModule,
    ViewPermissionDirectiveModule,
    NoConsecutiveSpacesDirectiveModule,
    SharedModule,
    AppStatus,
    NgxIntlTelInputModule,
    NgbDatepickerModule,
    OnlyIntegerDirectiveModule,
    LoaderModule,
    NgMultiSelectDropDownModule,
    QuillModule,
  ]
})
export class JobDetailComponent extends BaseComponent implements OnInit {


  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  public disableRoles: boolean = false;
  public isOpenSimilar: boolean = false;
  public userType: any = 'admin_user';
  public isContractModalOpen: boolean = false;

  // Sample contract details (these would likely come from an API or other source)
  contractDetails = {
    contractId: '12345',
    assignedFullName: 'John Doe',
    downPayment: 5000,
    finalPayment: 15000,
    totalBudget: 20000,
    startDate: new Date('2025-02-01'),
    endDate: new Date('2025-05-01'),
  };



  constructor(
      injector: Injector,
      private readonly pageSettingsService: PageSettingsService, // Service for managing page settings
    ) {
      super(injector);
      // If the page is not in edit mode, set the pageMode to 'add'
      if (this.isEditMode() == null) {
        this.pageMode = 'add';
      };

      const newSettings = {
        link1: {
          title: 'BREADCRUMBS.TASK_AND_PROJECTS.TITLE', // Breadcrumb title
          name: 'MENU.JOBS', // Breadcrumb name
          link: this.routeList.JOBS, // Link for the breadcrumb
        },
        link2: {
          title:'BREADCRUMBS.TASK_AND_PROJECTS.JOB_DETAIL',
        },
      };
      // Using the page settings service to update the page settings
      this.pageSettingsService.updatePageSettings(newSettings);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['type']) {
        this.userType = params['type'];
        if (this.userType == 'app_user' && this.pageMode == 'edit') {
          this.disableRoles = true;
        } else this.disableRoles = false;
      }
    });

    if (!this.baseModel.status) {
      this.baseModel.status = 'Published';
    };
  }

  protected onCancel(){
    this.router.navigate(['/jobs'])
  }

  // Method to open the contract modal
  openContractModal() {
    this.isContractModalOpen = true;
  }

  // Method to close the contract modal
  closeContractModal() {
    this.isContractModalOpen = false;
  }

}
