import { Routes, RouterModule } from '@angular/router';
import * as ROUTE_LIST from '@src/app/core/constants/routes.constant';
import { JobsComponent } from './jobs.component';
import {JobsListingComponent } from './views/jobs-listing/jobs-listing.component';
import {JobDetailComponent } from './views/job-detail/job-detail.component';

const routes: Routes = [
  {
    path: '',
    component: JobsComponent,
    children: [
      {
        path: '',
        // canActivate: [HasPermissionGuard],
        // data: { permission: PERMISSIONS_LIST.VIEW_USERS },
        component: JobsListingComponent,
      },
      {
        path: ROUTE_LIST.VIEW_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: JobDetailComponent,
      },
      {
        path: ROUTE_LIST.ADD_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: JobDetailComponent,
      },
      {
        path: ROUTE_LIST.EDIT_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },
        // canDeactivate: [UnsavedDataChangesGuard],
        component: JobDetailComponent,
        // resolve: {
        //   store: CategoryResolver,
        // },
      },
    ],
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
