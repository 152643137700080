import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from '@src/app/core/base';
import { DEFAULT_DEBOUNCE_TIME, S3_BUCKET_URL, TAG_TYPE } from '@src/app/core/constants';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { FileUploadService } from '@src/app/core/services/file-upload.service';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { S3Service } from '@src/app/core/services/s3.service';
import { UuidService } from '@src/app/core/services/uuid.service';
import { AppStatus } from '@src/app/core/shared/components/app-status/app-status.component';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import { LoaderModule } from '@src/app/core/shared/components/loader/loader.module';
import { NewProductPhotosInputComponent } from '@src/app/core/shared/components/new-product-photos-input/new-product-photos-input.component';
import { OnlyIntegerDirectiveModule } from '@src/app/core/shared/directives';
import { DebounceClickDirectiveModule } from '@src/app/core/shared/directives/debounceClick/debounceClick.directive.module';
import { NoConsecutiveSpacesDirectiveModule } from '@src/app/core/shared/directives/noConsecutiveSpaces/noConsecutiveSpaces.directive.module';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { RequestActvationType } from '@src/app/core/shared/interfaces/app-types';
import { BrandsRepository } from '@src/app/features/brands/core/repositories/brands.repository';
import { CategoryRepository } from '@src/app/features/categories/core/repositories/category.repository';
import { ItemsRepository } from '@src/app/features/item-management/core/repositories/items.repository';
import { StoreRepository } from '@src/app/features/store-management/core/repositories/store.repository';
import { SubCategoriesRepository } from '@src/app/features/sub-categories/core/repositories/sub-categories.repository';
import { TagsRepository } from '@src/app/features/tags/core/repositories/tags.repository';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-saz';
import { debounceTime, distinctUntilChanged, firstValueFrom, forkJoin, takeUntil } from 'rxjs';
import { SharedModule } from '../../../../core/shared/shared.module';
import { ITEM_BY_ID_BASE_URL, ITEM_STATUS_UPDATE } from '../../core/constants/apis_list';
import { Category } from '../../core/interfaces/category.enum';
import { Resource } from '../../core/interfaces/resource.enum';

export enum Role {
  Admin = 'admin',
  'Content Creator' = 'content_creator',
  Operator = 'operator',
}
@Component({
  selector: 'item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [
    ...COMMON_MODULES,
    NgbNavModule,
    NgbNavModule,
    NgSelectModule,
    ConfirmDialogModule,
    NgbDropdownModule,
    NgbTooltipModule,
    DebounceClickDirectiveModule,
    ViewPermissionDirectiveModule,
    NoConsecutiveSpacesDirectiveModule,
    SharedModule,
    AppStatus,
    NgxIntlTelInputModule,
    NewProductPhotosInputComponent,
    OnlyIntegerDirectiveModule,
    LoaderModule,
    NgMultiSelectDropDownModule,
  ],
})
export class ItemDetailComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  public dialogMessage: any = '';
  public dialogConfirmText: any = '';
  public confirmEventAction: string = '';
  public disableRoles: boolean = false;
  public isLoading: boolean = false;
  public isSaving: boolean = false;
  public isOpenAlternative: boolean = false;
  public isOpenSimilar: boolean = false;
  // public active: number = 1;
  public userType: any = 'admin_user';
  public hasSpecificRole: boolean = false;
  public CountryISO = CountryISO;
  public itemCoverImage: any = 'assets/images/thumbnail.svg';
  public coverImageFile: any = null;
  public phoneNumberFormat = PhoneNumberFormat;
  public SearchCountryField = SearchCountryField;
  public rolesList: any = Role;
  public S3_BUCKET_URL = S3_BUCKET_URL;
  public categoriesArray: Array<any> = [];
  public subCategoriesArray: Array<any> = [];
  public brandsArray: Array<any> = [];
  public storesArray: Array<any> = [];
  public styleTagArray: Array<any> = [];
  public materialTagArray: Array<any> = [];
  public finishTagArray: Array<any> = [];
  public similarProductsArray: Array<any> = [];
  public alternativeProductsArray: Array<any> = [];
  public productsArray = [];
  public multiDropdownSettings = {};
  public multiDropdownAlternativeItemsSettings = {};
  public multiDropdownSimilarItemsSettings = {};
  isCoverImageUploaded = true;

  @ViewChild(NewProductPhotosInputComponent)
  public photosInput!: NewProductPhotosInputComponent;

  public formGroup: FormGroup = new FormGroup({
    id: new FormControl(null),
    name: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    coverImagePath: new FormControl(null,[Validators.required]),
    images: new FormControl(null),
    categoryId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    subCategoryId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    storeId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    brandId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    styleId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    baseMaterialId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    finishId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    sku: new FormControl(null, [
      Validators.required,
      Validators.pattern(this.constantList.skuRegex),
    ]),
    price: new FormControl(null, [Validators.required]),
    order: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(99)]),
  });
  //   brandId: 2
  // coverImage: (binary)
  // alternatives: 1,2
  // price: 23.99
  // similar: 1,2
  // name: Product name
  // images: (binary)
  // tags: 1,2
  // subcategoryId: 11
  // itemOrder: 10
  // storeId: 2
  // description: Product description
  // sku: DS12935324
  formBackup = this.helperService.clone(this.formGroup.value);
  errorMessage!: any;

  public column: any = {
    key: 'status',
    value: 'COMPONENTS.ITEMS.STATUS',
    headingClass: 'ps-4',
    type: 'status',
    sortable: false,
    defaultTitle: true,
    apiCallType: RequestActvationType.RESOURCE_URL,
    options: [
      {
        label: 'GENERAL.BUTTONS.ACTIVATE',
        value: 'active',
        icon: 'tick-circle',
      },
      {
        label: 'GENERAL.BUTTONS.DEACTIVATE',
        value: 'inactive',
        icon: 'close-circle',
      },
    ],
    endpoint: ITEM_BY_ID_BASE_URL,
    success: this.translate.instant('MSGS.ITEMS.UPDATE_ITEM_STATUS'),
    successSecond: this.translate.instant('MSGS.ITEMS.UPDATE_ITEM_STATUS_DEACTIVATED'),
    alert: this.translate.instant('MSGS.ITEMS.ARE_YOU_SURE_DEACTIVATE'),
    alertSecond: this.translate.instant('MSGS.ITEMS.ARE_YOU_SURE_ACTIVATE'),
    error: this.translate.instant('MSGS.ITEMS.UNABLE_TO_UPDATE_ITEM_STATUS'),
  };
  uploadedFiles = 0;
  totalFiles = 0;

  /**
   * Component constructor
   * @param injector injects required services
   * @param pageSettingsService service for managing page settings in BaseComponent
   */
  constructor(
    injector: Injector,
    private repository: ItemsRepository,
    private categoryRepository: CategoryRepository,
    private subCategoryRepository: SubCategoriesRepository,
    private storeRepository: StoreRepository,
    private brandRepository: BrandsRepository,
    private tagsRepository: TagsRepository,
    private s3Service: S3Service,
    private pageSettingsService: PageSettingsService, // Service for managing page settings
    private fileUploadService: FileUploadService,
    private uuidService: UuidService,
  ) {
    super(injector);
    // If the page is not in edit mode, set the pageMode to 'add'
    if (this.isEditMode() == null) {
      this.pageMode = 'add';
    }

    this.getAllData();
    this.searchProducts();
    // Get the item from the resolver
    this.getItem();

    // Creating new page settings for the user management page
    const newSettings = {
      link1: {
        title: 'BREADCRUMBS.PRODUCTS_MANAGEMENT.TITLE', // Title for the page
        name: 'BREADCRUMBS.PRODUCTS_MANAGEMENT.ITEMS', // Name for the breadcrumb
        link: this.routeList.ITEMS, // Link for the breadcrumb
      },
      link2: {
        title:
          this.pageMode === 'add'
            ? 'BREADCRUMBS.PRODUCTS_MANAGEMENT.ADD_PRODUCT'
            : 'BREADCRUMBS.PRODUCTS_MANAGEMENT.EDIT_PRODUCT',
      },
    };

    // Using the page settings service to update the page settings
    this.pageSettingsService.updatePageSettings(newSettings);
  }

  removeAsyncValidationsAndError(controlName: string) {
    // Reset dirty state
    this.formGroup.get(controlName)?.markAsPristine();
    // Clear pending state (if any async validation is applied)
    this.formGroup.get(controlName)?.clearAsyncValidators();
    // Optionally, mark the control as untouched as well
    this.formGroup.get(controlName)?.markAsUntouched();
  }

  /**
   * Initializes the component and performs any necessary setup operations.
   *
   * This function is called when the component is first created and is used to
   * retrieve any necessary data from the URL parameters, such as the email and
   * token, and perform any initial setup tasks.
   *
   * @return {void} This function does not return a value.
   */
  /**
   * @description
   * Initializes component and retrieves user data from route resolver
   */
  ngOnInit(): void {
    setTimeout(() => {
      this.removeAsyncValidationsAndError('styleId');
      this.removeAsyncValidationsAndError('baseMaterialId');
      this.removeAsyncValidationsAndError('finishId');
    }, 0);
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['type']) {
        this.userType = params['type'];
        if (this.userType == 'app_user' && this.pageMode == 'edit') {
          this.disableRoles = true;
        } else this.disableRoles = false;
      }
    });
    /**
     * Retrieves user data from route resolver
     * Stores user data in component property `baseModel`
     * Also stores a copy of user's roles in `baseModel.rolesData`
     * Checks if user is a super admin
     *   and sets `pageMode` property to 'view' if true
     * Converts user's role IDs to an array of strings
     * Removes '+' prefix from phone numbers if present
     * Formats phone numbers to E.164 format
     * Removes 'null' and '+null' from landlineNumber
     * Formats date of birth to ISO format
     * Calls `setProfile`
     */
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.item) {
          this.baseModel = this.helperService.clone(data?.item);

          this.setItem();
        }
      });
    this.multiDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect all',
      allowSearchFilter: true,
    };
    this.multiDropdownAlternativeItemsSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      limitSelection: 6,
      allowSearchFilter: true,
    };
    this.multiDropdownSimilarItemsSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      limitSelection: 4,
      allowSearchFilter: true,
    };
    // this.onChanges();
    this.formGroup.get('categoryId')!.valueChanges.subscribe(data => {
      if (data != null) this.searchSubCategories(data);
    });

    this.formGroup.get('categoryId')?.valueChanges.subscribe((selectedCategoryId: number) => {
      // Reset the subCategoryId field
      this.formGroup.get('subCategoryId')?.reset();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.baseModel.photos != null) {
        this.photosInput.remotePhotosToDisplay = this.baseModel.photos;
      }
    }, 500); // Delay of 500 milliseconds

  }

  get isPhotosToSaveEmpty(): boolean {
    const noPhotosToSave = !this.photosInput?.photosToSave || this.photosInput.photosToSave.length === 0;
    const noRemotePhotosToDisplay = !this.photosInput?.remotePhotosToDisplay || this.photosInput.remotePhotosToDisplay.length === 0;

    // The button should be disabled only when BOTH are true (both conditions are met)
    return noPhotosToSave && noRemotePhotosToDisplay;
  }
  /**
   * Subscribes to the data observable from the ActivatedRoute and
   * sets the baseModel property with the data from the observable.
   */
  getItem() {
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.item) {
          /**
           * The store data from the ActivatedRoute data observable.
           */
          this.baseModel = data?.item;
        }
      });
  }

  /**
   * Reset the password form fields
   *
   * @param form The form to reset
   */
  resetPasswordForm(form: any) {
    // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
    form.reset(); // Reset the form fields
  }

  /**
   * Get role details by ID
   *
   * @param id The ID of the role
   * @returns The role details if found, empty object otherwise
   */
  getRoleDetailsbyId(id: any): any {
    const index = this.rolesList.findIndex((s: any) => s.id == id);
    if (index !== -1) {
      return this.rolesList[index];
    } else {
      return {}; // Empty object if not found
    }
  }

  /**
   * Finds all invalid controls in the given form
   * @param form The form to search for invalid controls
   * @returns The names of the invalid controls
   */
  public findInvalidControls(form: any): string[] {
    const invalid = []; // The names of the invalid controls
    const controls = form.controls; // The controls of the given form

    // Loop through all the controls in the form
    for (const name in controls) {
      // If the control is invalid
      if (controls[name].invalid) {
        // Add its name to the list of invalid controls
        invalid.push(name);
      }
    }
    return invalid;
  }

  /**
   * Updates the status of the segment.
   *
   * @param {string} status - The new status of the segment.
   * @return {void} This function does not return anything.
   */
  public updateStatus(status: string) {
    this.httpService
      .requestEntity('PATCH', ITEM_STATUS_UPDATE.replace('{id}', this.baseModel?.id), {
        status: status,
      })
      .pipe(this.destroy$())
      .subscribe({
        /**
         * Handles the next callback of the subscription.
         *
         * @param {any} res - The response object.
         * @return {void} This function does not return anything.
         */
        next: (res: any) => {
          if (
            res.status === this.constantList.SUCCESS_STATUS ||
            res.status === this.constantList.SUCCESS_STATUS_CODE
          ) {
            this.httpService.showMessage(
              this.translate.instant('MSGS.GENERAL.STATUS_UPDATED_SUCCESS'),
              'success',
            );
            this.baseModel.isActivated = res.body?.isActivated;
          }
        },
      });
  }

  // /**
  //  * Submits store based on the specified type.
  //  *
  //  * @param {string} type - The type of submission ('draft' or 'create').
  //  * @return {void} This function does not return a value.
  //  */
  // protected async  onSubmit(type: string) {

  //   let { valid, value } = this.formGroup;
  //   if (valid) {
  //   this.isSaving = true;
  //   this.totalFiles = this.getTotalFiles();
  //   let payload: any = {};
  //   let allTags: number[] = [];
  //   let alternativeItemsIDs: number[] = [];
  //   let similarItemsIDs: number[] = [];

  //   if (type === 'add') {
  //     if (!this.coverImageFile?.file) {
  //       this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.COVER_IMAGE_REQUIRED'), 'error');
  //        this.isSaving = false;
  //       return;
  //     }
  //     value.coverImagePath = this.coverImageFile?.file;
  //     if (!this.isCoverImageUploaded) {
  //       const fileToUpload = this.coverImageFile?.file;
  //       const key = `products/${this.coverImageFile?.file.name}_${Date.now()}.png`;
  //       const uploadResponse = await this.uploadFile(this.coverImageFile?.file, key);

  //       this.isCoverImageUploaded = true;
  //       this.itemCoverImage = S3_BUCKET_URL + uploadResponse.key;
  //       payload.coverImage = this.itemCoverImage;
  //       this.uploadedFiles++;
  //     } else {
  //       payload.coverImage = this.itemCoverImage;
  //     }
  //   } else if (type === 'update') {
  //     if (this.coverImageFile?.file) {
  //       if (!this.isCoverImageUploaded) {
  //         const fileToUpload = this.coverImageFile?.file;
  //         const key = `products/${this.coverImageFile?.file.name}_${Date.now()}.png`;
  //         const uploadResponse = await this.uploadFile(this.coverImageFile?.file, key);

  //         this.isCoverImageUploaded = true;
  //         this.itemCoverImage = S3_BUCKET_URL + uploadResponse.key;
  //         payload.coverImage = this.itemCoverImage;
  //         this.uploadedFiles++;
  //       }
  //     }
  //     if (this.photosInput.photosToDelete && this.photosInput.photosToDelete.length) {
  //       payload.deleteImages = this.photosInput.photosToDelete; //.map(element => element.toString());
  //     }
  //   }

  //   const images: string[] = [];
  //   for (const file of this.photosInput.photosToSave) {
  //     if (!file.isUpload) {
  //       const fileToUpload = file.file;
  //       const key = `products/${file.file.name}_${Date.now()}.png`;
  //       const uploadResponse = await this.uploadFile(file.file, key);

  //       file.isUpload = true;
  //       file.url = S3_BUCKET_URL + uploadResponse.key;
  //       images.push(file.url);
  //       this.uploadedFiles++;
  //     }
  //   }
  //   payload.images = this.photosInput.photosToSave.filter(x=>x.isUpload).map(a => a.url);

  //   value.styleId.forEach((tag: any) => allTags.push(tag.id));
  //   value.baseMaterialId.forEach((tag: any) => allTags.push(tag.id));
  //   value.finishId.forEach((tag: any) => allTags.push(tag.id));
  //   payload.tags = allTags;

  //   payload.name = value.name;
  //   payload.description = value.description;
  //   payload.price = value.price;
  //   payload.sku = value.sku;
  //   payload.itemOrder = value.order;
  //   payload.subcategoryId = value.subCategoryId;
  //   payload.storeId = value.storeId;
  //   payload.brandId = value.brandId;

  //   if (this.alternativeProductsArray && this.alternativeProductsArray.length) {
  //     alternativeItemsIDs = this.alternativeProductsArray.map(item => item.id);
  //   }
  //   if (this.similarProductsArray && this.similarProductsArray.length) {
  //     similarItemsIDs = this.similarProductsArray.map(item => item.id);
  //   }
  //   payload.alternatives = alternativeItemsIDs;
  //   payload.similar = similarItemsIDs;

  //   // const payload = this.formDataToJson(formData);
  //   // payload['images'] = Images;
  //   console.log('Object.fromEntries(formData)', payload);
  //   // return;
  //   // formData.forEach(item =>{
  //   //   console.log(item)
  //   // });
  //     // Determine API endpoint and HTTP method based on whether it's an update or creation
  //     let apiContainer = this.repository.create(payload);
  //     if (this.baseModel?.id) {
  //       apiContainer = this.repository.update(this.baseModel.id, payload);
  //     }

  //     apiContainer
  //       .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
  //       .subscribe({
  //         /**
  //          * Executes the next callback function asynchronously. If the response status is SUCCESS_STATUS or SUCCESS_STATUS_CODE,
  //          * it updates the calendar and shows error messages if any. Otherwise, it shows success message.
  //          *
  //          * @param {any} res - The response object.
  //          * @return {Promise<void>} A promise that resolves when the function completes.
  //          */
  //         next: async (res: any) => {
  //           this.isSaving = false
  //           this.httpService.showMessage(
  //             `${type === 'update'
  //               ? this.translate.instant('MSGS.ITEMS.ITEM_UPDATED')
  //               : this.translate.instant('MSGS.ITEMS.ITEM_CREATED')
  //             }`,
  //             'success',
  //           );
  //           this.router.navigate([this.routeList.ITEMS]);
  //         },
  //         error: async (err: any) => {
  //           this.isSaving = false
  //         }
  //       });
  //   } else {
  //     this.formGroup.markAllAsTouched();
  //   }
  // }

  /**
   * Submits the store based on the specified type (add/update).
   *
   * @param {string} type - The submission type ('add' or 'update').
   */
  protected async onSubmit(type: string) {
    let { valid, value } = this.formGroup;
    this.isSaving = true;
    this.totalFiles = this.getTotalFiles();

    if (!valid) {
      this.formGroup.markAllAsTouched();
      this.isSaving = false;
      return;
    }

    this.isSaving = true;

    try {
      // Prepare payload
      const payload = await this.preparePayload(type, value);

      // Submit to API
      await this.submitToApi(type, payload);

      this.httpService.showMessage(
        `${
          type === 'update'
            ? this.translate.instant('MSGS.ITEMS.ITEM_UPDATED')
            : this.translate.instant('MSGS.ITEMS.ITEM_CREATED')
        }`,
        'success',
      );
      this.router.navigate([this.routeList.ITEMS]);
    } catch (error) {
      console.error('Error during submission:', error);
      this.isSaving = false;
    }
  }

  /**
   * Prepares the payload for submission based on the type and form values.
   *
   * @param {string} type - The submission type ('add' or 'update').
   * @param {any} value - The form values.
   * @returns {Promise<any>} The prepared payload.
   */
  private async preparePayload(type: string, value: any): Promise<any> {
    const payload: any = {};
    const allTags: number[] = [];

    // Consolidate all files for upload (cover image + additional images)
    const filesToUpload: any[] = [];
    if (this.coverImageFile?.file) {
      filesToUpload.push({
        file: this.coverImageFile.file,
        field: Category.ITEMS,
      });
    }

    this.photosInput.photosToSave.forEach((file: any) => {
      filesToUpload.push({
        file: file.file,
        field: Category.COVER,
      });
    });
    const id = type === 'add' ? this.uuidService.generateUuid() : value.id;

    // Handle multiple file uploads and populate payload with uploaded file paths
    const uploadedFiles = await this.handleMultipleFileUploads(id, filesToUpload);
    // Iterate through the uploaded files one by one
    uploadedFiles.forEach((file, index) => {
      console.log(`File #${index + 1}:`, file); // Log each file
      this.uploadedFiles++; // Increment the count for uploadedFiles
    });
    payload.images = uploadedFiles
      .filter(file => file.field === Category.COVER) // Use `field` to filter images
      .map(file => file.fileName);

    payload.coverImage = uploadedFiles
      .filter(file => file.field === Category.ITEMS) // Filter by the 'coverImage' field
      .map(file => file.fileName)[0];

    // Add tags
    value.styleId.forEach((tag: any) => allTags.push(tag.id));
    value.baseMaterialId.forEach((tag: any) => allTags.push(tag.id));
    value.finishId.forEach((tag: any) => allTags.push(tag.id));
    payload.tags = allTags;

    payload.subcategoryId = value.subCategoryId;

    // Map alternative and similar product IDs
    payload.alternatives = this.alternativeProductsArray?.map(item => item.id) || [];
    payload.similar = this.similarProductsArray?.map(item => item.id) || [];

    // Map other form values based on type
    if (type === 'add') {
      // Fields required for 'add'
      Object.assign(payload, {
        name: value.name,
        tempUUID: id,
        description: value.description,
        price: value.price,
        sku: value.sku,
        itemOrder: value.order,
        subcategoryId: value.subCategoryId,
        storeId: value.storeId,
        brandId: value.brandId,
      });

      // Optionally validate required fields for 'add'
      if (!payload.coverImage) {
        throw new Error('Cover image is required for new items.');
      }
    } else if (type === 'update') {
      // Handle file uploads and deletion
      if (this.photosInput.photosToDelete && this.photosInput.photosToDelete.length > 0) {
        payload.deleteImages = this.photosInput.photosToDelete.map((image: any) => {
          if (typeof image === 'object') {
            // Ensure id is converted to a number if it exists
            return image.id ? Number(image.id) : image.url;
          }
          // Convert string to number if it's not already
          return Number(image);
        });
      }
      // Only include fields that are allowed to be updated
      Object.assign(payload, {
        name: value.name,
        description: value.description,
        price: value.price,
        itemOrder: value.order,
      });

      // Skip validation or additional fields as per business logic
    }

    console.log('payload', payload);

    return payload;
  }

  /**
   * Handles uploading multiple files and returns the updated file names and fields.
   *
   * @param {string} resourceId - The resource ID (e.g., product ID).
   * @param {any[]} filesToUpload - Array of files and their associated fields.
   * @returns {Promise<any[]>} Array of updated file names and fields.
   */
  private async handleMultipleFileUploads(
    resourceId: string,
    filesToUpload: any[],
  ): Promise<any[]> {
    const fileNames = filesToUpload.map(file => file.file.name);
    const files = filesToUpload.map(file => file.file);
    const fields = filesToUpload.map(file => file.field);
    if (!fileNames.length || !files.length) {
      return [];
    }

    try {
      const uploadResults = await firstValueFrom(
        this.fileUploadService.uploadFilesToResource(
          Resource.ITEMS,
          resourceId,
          fileNames,
          files,
          fields,
        ),
      );
      return uploadResults || [];
    } catch (error) {
      console.error('Error during file upload:', error);
      return [];
    }
  }

  /**
   * Submits the prepared payload to the API based on the type.
   *
   * @param {string} type - The submission type ('add' or 'update').
   * @param {any} payload - The prepared payload.
   * @returns {Promise<void>}
   */
  private async submitToApi(type: string, payload: any): Promise<void> {
    console.log(payload);
    const apiCall = this.baseModel?.id
      ? this.repository.update(this.baseModel.id, payload)
      : this.repository.create(payload);

    return new Promise((resolve, reject) => {
      apiCall
        .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
        .subscribe({
          next: () => resolve(),
          error: err => reject(err),
        });
    });
  }

  // formDataToJson(formData: FormData): Record<string, any> {
  //   const json: Record<string, any> = {};

  //   // Iterate through FormData entries
  //   formData.forEach((value, key) => {
  //     // Handle multiple values for the same key (e.g., file inputs)
  //     if (json[key]) {
  //       if (Array.isArray(json[key])) {
  //         json[key].push(value);
  //       } else {
  //         json[key] = [json[key], value];
  //       }
  //     } else {
  //       json[key] = value;
  //     }
  //   });

  //   return json;
  // }

  /**
   * Sets the form values to the base model
   *
   * This function is called in the ngOnInit lifecycle hook,
   * after getting the user profile from the API.
   *
   * It patches the formGroup with the baseModel values,
   * and updates the formGroup validity to emit events.
   */
  setItem(id?: string) {
    this.formGroup.updateValueAndValidity({ emitEvent: false });
    if (id === 'cancel') {
      this.formGroup.patchValue({}, { emitEvent: false });
      this.formGroup.reset();
      this.removeAsyncValidationsAndError('styleId');
      this.removeAsyncValidationsAndError('baseMaterialId');
      this.removeAsyncValidationsAndError('finishId');
      this.photosInput.removeAllPhotos();
      this.itemCoverImage = 'assets/images/thumbnail.svg';
      this.coverImageFile = null;
      this.similarProductsArray = [];
      this.alternativeProductsArray = [];
      this.subCategoriesArray = [];
      this.helperService.goToRoute(this.routeList.ITEMS);
    } else {
      // this.formGroup.patchValue({ ...this.baseModel }, { emitEvent: false });
      this.searchSubCategories(this.baseModel.subcategories[0]?.parentCategory.id);
      this.formGroup.patchValue({
        name: this.baseModel.name, // Set subCategoryId
        description: this.baseModel.description,
        id: this.baseModel.id,
        price: this.baseModel.price,
        sku: this.baseModel.sku,
        order: this.baseModel.itemOrder,
        coverImagePath: this.baseModel.coverPhoto,
        brandId: this.baseModel.brand?.id,
        subCategoryId: this.baseModel.subcategories[0]?.id,
        categoryId: this.baseModel.subcategories[0]?.parentCategory.id,
        storeId: this.baseModel.store?.id,
        isActivated: this.baseModel.isActivated,
        styleId: this.baseModel.tags?.filter((tag: any) => tag.type === TAG_TYPE.style),
        baseMaterialId: this.baseModel.tags?.filter(
          (tag: any) => tag.type === TAG_TYPE.base_material,
        ),
        finishId: this.baseModel.tags?.filter((tag: any) => tag.type === TAG_TYPE.finish),
      });
      this.itemCoverImage = this.baseModel.coverPhoto;
      this.alternativeProductsArray = this.baseModel.alternatives;
      this.similarProductsArray = this.baseModel.similar;
    }
    if (id === 'reset') {
      this.photosInput.removeAllPhotos();
      this.photosInput.remotePhotosToDisplay = this.baseModel.photos;
      this.coverImageFile = null;
    }
  }

  onPictureUpload(event: any) {
    const file = event.target.files[0]; // Get the uploaded file

    if (!file) {
      return; // Exit if no file is selected
    }

    const files = [];
    files.push(file);
    // Allowed MIME types and extensions
    const allowedFormats = ['image/png', 'image/jpg', 'image/jpeg'];
    const allowedExtensions = ['png', 'jpg', 'jpeg'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase(); // Extract file extension

    // Validate file type and extension
    if (!allowedFormats.includes(file.type) || !allowedExtensions.includes(fileExtension)) {
      this.httpService.showMessage(
        this.translate.instant('MSGS.GENERAL.INVALID_FILE_FORMAT'),
        'error',
      );
      console.error('Invalid file format or extension:', file.type, fileExtension);
      return; // Stop further processing
    }

    // Process the uploaded image
    this.coverImageFile = {
      file: file,
      fileName: file.name,
    };

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result; // Use FileReader result as the image source

      img.onload = () => {
        this.itemCoverImage = reader.result; // Set the preview
        this.isCoverImageUploaded = false; // Mark as not uploaded
      };
    };

    reader.readAsDataURL(file);
  }

  //fetch categories
  searchCategories() {
    this.categoryRepository
      .search()
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          if (res?.body) {
            this.categoriesArray = res.body.data.filter((category: any) => category.isActivated);
          }
        },
      });
  }

  //fetch sub categories
  searchSubCategories(id: string) {
    this.subCategoryRepository
      .search({
        'filter.isActivated': true,
        'filter.parentCategoryId': id,
        limit: 100,
      })
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          if (res?.body) {
            this.subCategoriesArray = res.body.data;
          }
        },
      });
  }

  //fetch store
  searchStores() {
    this.storeRepository
      .search()
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          if (res?.body) {
            this.storesArray = res.body.data.filter((store: any) => store.isActivated);
          }
        },
      });
  }

  //fetch brands
  searchBrands() {
    this.brandRepository
      .search()
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          if (res?.body) {
            this.brandsArray = res.body.data.filter((brand: any) => brand.isActivated);
          }
        },
      });
  }

  searchProducts(keyword: string = '') {
    this.repository
      .search({
        'filter.isActivated': true,
        search: keyword,
        limit: 100,
      })
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          if (res?.body) {
            this.productsArray = res.body.data;
          }
        },
      });
  }

  // get all data
  getAllData() {
    this.isLoading = true;
    const categoriesCall = this.categoryRepository.search({
      'filter.isActivated': true,
      limit: 100,
    });
    // const subCategoriesCall = this.subCategoryRepository.search({
    //   'filter.isActivated': true
    // });
    const storesCall = this.storeRepository.search({
      'filter.isActivated': true,
      limit: 100,
    });
    const brandsCall = this.brandRepository.search({
      'filter.isActivated': true,
      limit: 100,
    });
    const styleTagsCall = this.tagsRepository.search({
      'filter.isActivated': true,
      'filter.type': TAG_TYPE.style,
      limit: 100,
    });
    const materialTagsCall = this.tagsRepository.search({
      'filter.isActivated': true,
      'filter.type': TAG_TYPE.base_material,
      limit: 100,
    });
    const finishTagsCall = this.tagsRepository.search({
      'filter.isActivated': true,
      'filter.type': TAG_TYPE.finish,
      limit: 100,
    });
    forkJoin([
      categoriesCall,
      // subCategoriesCall,
      storesCall,
      brandsCall,
      styleTagsCall,
      materialTagsCall,
      finishTagsCall,
    ]).subscribe(response => {
      this.isLoading = false;
      const resCategories = response[0] as ApiResponseInterface<any>;
      // const resSubCategories = response[1] as ApiResponseInterface<any>;
      const resStores = response[1] as ApiResponseInterface<any>;
      const resBrands = response[2] as ApiResponseInterface<any>;
      const resStyleTags = response[3] as ApiResponseInterface<any>;
      const resMaterialTags = response[4] as ApiResponseInterface<any>;
      const resFinishTags = response[5] as ApiResponseInterface<any>;
      if (resCategories?.body) {
        this.categoriesArray = resCategories.body.data;
      }
      // if (resSubCategories?.body) {
      //   this.subCategoriesArray = resSubCategories.body.data;
      // }
      if (resStores?.body) {
        this.storesArray = resStores.body.data;
      }
      if (resBrands?.body) {
        this.brandsArray = resBrands.body.data;
      }
      if (resStyleTags?.body) {
        this.styleTagArray = resStyleTags.body.data;
      }
      if (resMaterialTags?.body) {
        this.materialTagArray = resMaterialTags.body.data;
      }
      if (resFinishTags?.body) {
        this.finishTagArray = resFinishTags.body.data;
      }
    });
  }

  // Upload file to the S3 service
  // async uploadFile(file: File, key: string) {
  //   const itemFiles = [];
  //   itemFiles.push(file);
  //   console.log('Item', itemFiles);

  //   try {
  //     const response = await firstValueFrom(this.s3Service.uploadFile(file, key));
  //     return 'response';
  //   } catch (error) {
  //     this.errorMessage = error;
  //     console.error(error);
  //   }
  // }

  getTotalFiles() {
    return (
      (this.photosInput && this.photosInput.photosToSave.filter(x => x.isUpload == false).length) +
      (this.isCoverImageUploaded ? 0 : 1)
    );
  }

  // Upload progress calculations
  get uploadProgress(): number {
    return this.totalFiles ? (this.uploadedFiles / this.totalFiles) * 100 : 0;
  }

  get uploadStatus(): string {
    return `${this.uploadedFiles} out of ${this.totalFiles} files uploaded`;
  }


validateSelection(selectedItems: any[], otherList: any[]): any[] {
  const currentItemId = this.baseModel.id; // Current product ID

  //Create a Set of existing IDs from the other list to prevent cross-selection
  const existingIds = new Set(otherList.map(item => item.id));

  // Filter out invalid selections
  return selectedItems.filter(item => 
    item.id !== currentItemId && !existingIds.has(item.id)
  );
}

onAlternativeChange(selectedItems: any[]): void {
  // Filter the selected items (remove duplicates & invalid items)
  const filteredSelection = this.validateSelection(selectedItems, this.similarProductsArray);

  // Only update if the selection actually changed
  if (filteredSelection.length !== this.alternativeProductsArray.length) {
    this.alternativeProductsArray = filteredSelection;
    this.httpService.showMessage(this.translate.instant('MSGS.ITEMS.INVALID_ITEM_SELECTION'), 'error');
  }
}

onSimilarChange(selectedItems: any[]): void {
  // Filter the selected items (remove duplicates & invalid items)
  const filteredSelection = this.validateSelection(selectedItems, this.alternativeProductsArray);

  // Only update if the selection actually changed
  if (filteredSelection.length !== this.similarProductsArray.length) {
    this.similarProductsArray = filteredSelection;
    this.httpService.showMessage(this.translate.instant('MSGS.ITEMS.INVALID_ITEM_SELECTION'), 'error');
  }
}
}
